import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import GoogleTagManager from 'react-gtm-module';
import { logging } from 'w3-user-ui-component';
import uilibPackageJson from 'w3-user-ui-component/package.json';
import packageJson from '../package.json';
import { UserStateProvider } from './context/UserStateContext';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RefProvider } from './context/RefContext';
import './index.scss';
import { FeatureFlagsProvider } from './context/FeatureFlagsContext';

logging.logAll('My learning version: ', packageJson.version);
logging.logAll('Shared lib version: ', uilibPackageJson.version);

GoogleTagManager.initialize({
  gtmId: 'GTM-KTCFC3S',
  dataLayer: {
    js: new Date(),
  },
});

const elements = document.getElementsByTagName('html');

if (elements?.length) {
  elements[0].style.scrollBehavior = 'auto';
}

ReactDOM.render(
  <Router>
    <UserStateProvider>
      <RefProvider>
        <FeatureFlagsProvider>
          <App version={packageJson.version} />
        </FeatureFlagsProvider>
      </RefProvider>
    </UserStateProvider>
  </Router>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
