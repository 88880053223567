// @ts-nocheck
/* eslint-disable */
import { logging } from 'w3-user-ui-component';
import UserStateService from '../api/services/UserStateService';
import { DEFAULT_USER_STATE } from '../context/UserStateContext';
import { IUser, IUserInfo } from '../types/IUser';

const getUserState = async (): Promise<IUser | undefined> => {
  const result = await UserStateService.getUserState();

  logging.logDebug('contextManager -> getUserState -> result: ', result);

  if (result.error.code !== '0') {
    // console.warn('error', result.error);
    return undefined;
  }

  return result.data;
};

export const getUserInfo = async (): Promise<IUserInfo | undefined> => {
  const userInfo = await UserStateService.getUserInfo();
  if (userInfo) {
    return userInfo;
  }
  return undefined;
};

export const fetchUserState = async (stateContext: any) => {
  const userState = await getUserState();
  const userInfo = await getUserInfo();

  stateContext.setUserState({ ...(userState ?? DEFAULT_USER_STATE), userInfo });
};
