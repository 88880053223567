import React, { createContext, FC, useMemo, useState } from 'react';

export interface IFeatureFlags {
  noUpsell: boolean;
}

export const DEFAULT_FEATURE_FLAGS: IFeatureFlags = {
  noUpsell: false,
};

export interface IFeatureFlagsContext {
  featureFlags: IFeatureFlags;
  setFeatureFlags: (featureFlags: IFeatureFlags) => void;
}

const FeatureFlagsContext = createContext<IFeatureFlagsContext>({
  featureFlags: DEFAULT_FEATURE_FLAGS,
  setFeatureFlags: () => { },
});

const FeatureFlagsProvider: FC = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState<IFeatureFlags>(
    DEFAULT_FEATURE_FLAGS,
  );

  const value: IFeatureFlagsContext = useMemo(
    () => ({ featureFlags, setFeatureFlags }),
    [featureFlags],
  );

  return (
    <FeatureFlagsContext.Provider value={value}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export { FeatureFlagsProvider, FeatureFlagsContext };
