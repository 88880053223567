import React from 'react';
import { LoadingBox } from 'w3-user-ui-component';

interface IComponentLoader {
}

// eslint-disable-next-line no-empty-pattern
const ComponentLoader = ({ }: IComponentLoader) => (
  <LoadingBox text="Loading component..." aria-label="Loading component" />
);

ComponentLoader.defaultProps = {
};

export default ComponentLoader;
