import React, { createContext, FC, useState, useMemo } from 'react';

export interface IRefComponent {
  key: string,
  reference: any,
}

export type RefState = {
  refMapper: { [key: string]: IRefComponent }
  // eslint-disable-next-line no-unused-vars
  setReferenceComponnet: (ref: IRefComponent) => void
};

const DEFAULT_STATE: RefState = {
  refMapper: {},
  setReferenceComponnet: () => { },
};

const RefContext = createContext<RefState>(DEFAULT_STATE);

const RefProvider: FC = ({ children }) => {
  const [refMapper, setRefMapper] = useState<{ [key: string]: IRefComponent }>({});

  const value: RefState = useMemo(
    () => ({
      refMapper, setReferenceComponnet: (ref: IRefComponent) => {
        refMapper[ref.key] = ref;
        setRefMapper(refMapper);
      },
    }),
    [],
  );

  return (
    <RefContext.Provider value={value}>
      {children}
    </RefContext.Provider>
  );
};

export { RefProvider, RefContext };
