import { auth, fetchMyLearning, fetchCert, getCookie } from 'w3-user-ui-component';
import { IUser } from '../../types/IUser';
import { IResetProgress } from '../../types/IUserTutorialProgress';

const UserStateService = {
  processUserSession: auth.processUserSession,

  getUserInfo: async () => auth.getUserInfoFromCookies(),

  getUserState: async () => fetchMyLearning<IUser>({
    url: '/user/state',
    method: 'GET',
    headers: {
      'Cache-Control': 'no-cache',
    },
  }),

  getUserCerts: async () => fetchCert<any>({
    url: '/certifications-state',
    method: 'GET',
    attachCookies: true, // auth via cookies?
    authenticated: true, // auth via headers?
    headers: {
      uic: getCookie('__c_u_i_1') as string,
    },
  }),

  resetTutorialProgress: async (data: IResetProgress): Promise<boolean> => {
    const reqRes = await fetchMyLearning<any>({
      url: '/user/reset-progress',
      method: 'POST',
      data,
      headers: {
        'Cache-Control': 'no-cache',
      },
    });

    return reqRes.error.code === '0';
  },

};

export default UserStateService;
