/* eslint-disable max-len */
import React, { useEffect, useState, useRef, useContext, Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Terms, LoadingBox, auth, setDefaultFetchConfig, logging, TopNavBar } from 'w3-user-ui-component';
import 'w3-user-ui-component/dist/index.css';
import useUserState from './api/hooks/useUserState';
import ScrollToTop from './components/shared/ScrollToTop';
import { RefContext } from './context/RefContext';
import userStateService from './api/services/UserStateService';
import { ICertificateData } from './data/Struct/Classic/InterfacesAndTypes';
import ComponentLoader from './components/ComponentLoader/ComponentLoader';
import { getUserInfo } from './contextManager/userState';

import './App.scss';
import { FeatureFlagsContext } from './context/FeatureFlagsContext';

const ClaimToken = React.lazy(() => import('./components/ClaimToken/ClaimToken'));
const AllTopics = React.lazy(() => import('./components/topic/AllTopics'));
const Topic = React.lazy(() => import('./components/topic/Topic'));
const Certificates = React.lazy(() => import('./components/Certificates/Certificates'));
const CareerPath = React.lazy(() => import('./components/CareerPath/CareerPath'));
// const NotFound = React.lazy(() => import('./components/Common/NotFound'));
// const Home = React.lazy(() => import('./components/home/Home'));
const VideoHtml = React.lazy(() => import('./components/Videos/VideoHtml'));
const RedirectToPathfinderLearningPaths = React.lazy(() => import('./components/Pathfinder/RedirectToPathfinderLearningPaths'));

const ClaimTokenUrl = 'get-access';

const Paths = {
  Certificate: '/certificates',
  AllTutorials: '/tutorials',
  Tutorial: '/tutorial/:code',
  Career: '/career-path/:id',
  Claim: `/${ClaimTokenUrl}/:id`,
  HTMLVideo: '/videos/html',
  Default: '/',
};

interface IApp {
  version: string;
}

const App = ({
  // eslint-disable-next-line
  version,
}: IApp) => {
  const { setReferenceComponnet } = useContext(RefContext);
  const footerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUserState, userSessionIsProcessed, isActiveUserSession] = useUserState();
  const [userExams, setUserExams] = useState<ICertificateData[] | undefined>();
  // const [errorFetchingUserData, setErrorFetchingUserData] = useState(false);
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [fetchUserCertsTimestamp, setFetchUserCertsTimestamp] = useState<number | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [subscriptionPlan, setSubscriptionPlan] = useState<string>('free');
  const { setFeatureFlags, featureFlags } = useContext(FeatureFlagsContext);

  window.history.scrollRestoration = 'manual';

  useEffect(() => {
    setDefaultFetchConfig({
      refreshTokenOnFailedCognitoAuth: true,
      processUserSessionRef: auth.processUserSession,
      changeStatusCodeInUserSessionCookiesRef: auth.changeStatusCodeInUserSessionCookies,
    });

    setReferenceComponnet({ key: 'footer', reference: footerRef });
  }, []);

  useEffect(() => {
    setIsLoading(isLoadingUserState);
  }, [isLoadingUserState]);

  if (userSessionIsProcessed && !isActiveUserSession) {
    // eslint-disable-next-line no-debugger
    debugger;
    auth.restartUserSessionViaRedirect({
      context: 'MyLearningAppInit',
      originUrl: window.location.href,
      reason: {
        userSessionIsProcessed,
        isActiveUserSession,
      },
    });
    return null;
  }

  const location = useLocation();

  useEffect(() => {
    try {
      getUserInfo().then((res: any) => {
        logging.logDebug('App -> got subscription plan', res);
        if (res) {
          setSubscriptionPlan(res.plan);
          setFeatureFlags({
            noUpsell: res.noUpsell || false,
          });
        }
      });
    } catch (error) {
      logging.logError('App -> error subscription plan', error);
    }
  }, []);

  useEffect(() => {
    const nowTime = new Date().getTime();
    const oneHourTime = fetchUserCertsTimestamp ? new Date(fetchUserCertsTimestamp + 1 * 60 * 60 * 1000).getTime() : 0; // add 1 hour to when we last got cert data
    const firstPartUrl = location.pathname.split('/')[1];

    if (!isLoadingUserState && location.pathname !== Paths.Certificate && firstPartUrl !== ClaimTokenUrl && oneHourTime <= nowTime) {
      logging.logDebug('App -> Fetching user certs -> [fetchUserCertsTimestamp, nowTime, oneHourTime]', fetchUserCertsTimestamp, nowTime, oneHourTime);

      try {
        setLoadingUserData(true);

        userStateService
          .getUserCerts()
          .then((res: any) => {
            logging.logDebug('App -> got user certs', res);
            if (res.error.code === '0') {
              if (!res.data.certificates) {
                logging.logDebug('App -> useEffect isLoadingUserState/location hook -> res.data.message: ', res.data.message);
              } else if (res.data.certificates && !Array.isArray(res.data.certificates)) {
                setUserExams([]);
                setFetchUserCertsTimestamp(new Date().getTime());
              } else {
                setUserExams([...res.data.certificates]);
                setFetchUserCertsTimestamp(new Date().getTime());
              }
            } else if (res.error.code === 'INTERNAL_SERVER_ERROR') {
              logging.logError('App -> useEffect isLoadingUserState/location hook -> INTERNAL_SERVER_ERROR: ', res.error);
              // setErrorFetchingUserData(true);
            } else {
              logging.logError('App -> useEffect isLoadingUserState/location hook -> res.error: ', res.error);
              // setErrorFetchingUserData(true);
            }
          })
          .finally(() => {
            setLoadingUserData(false);
          });
      } catch (error) {
        logging.logError('App -> error user certs', error);
        setUserExams([]);
        // setErrorFetchingUserData(true);
        setLoadingUserData(false);
      }
    }
  }, [isLoading, location]);

  return (
    <div className="App" style={{ height: '100vh', paddingTop: '26px' }}>
      <TopNavBar
        zIndex={999999}
        userSessionProcessed={isActiveUserSession}
        userSessionMeta={{
          loggedIn: isActiveUserSession,
          subscriptionPlan,
          featureFlags,
        }} />

      {(isLoading || !isActiveUserSession) && (
        <div className="justify-content-center">
          <LoadingBox text_placement="top" position="absolute" />
        </div>
      )}
      {!isLoading && isActiveUserSession && (
        <>
          <ScrollToTop />
          <Suspense fallback={<ComponentLoader />}>
            <Routes>
              <Route path={`/deprecated/${Paths.Career}`} element={<CareerPath certs={userExams} />} />
              <Route path={`/deprecated/${Paths.Tutorial}`} element={<Topic certs={userExams} loadingUserData={loadingUserData} />} />
              <Route path={`/deprecated/${Paths.AllTutorials}`} element={<AllTopics />} />
              <Route path={Paths.Certificate} element={<Certificates />} />
              <Route path={Paths.Claim} element={<ClaimToken />} />
              <Route path={Paths.HTMLVideo} element={<VideoHtml />} />
              {/* <Route path={Paths.Default} element={<AllTopics />} /> */}
              {/* <Route path={Paths.Default} element={<Home />} /> */}
              {/* <Route path="*" element={<NotFound />} /> */}
              <Route path={Paths.Default} element={<RedirectToPathfinderLearningPaths />} />
              <Route path="*" element={<RedirectToPathfinderLearningPaths />} />
            </Routes>
          </Suspense>
        </>
      )}
      {/* <div ref={footerRef} className={location.pathname === Paths.Default ? 'app-footer-dark' : 'app-footer'}> */}
      <div ref={footerRef} className="app-footer">
        <Terms position={isLoading ? 'fixed' : 'relative'} />
      </div>
    </div>
  );
};

export default App;
