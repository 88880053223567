import { useContext, useEffect, useState } from 'react';
import { UserStateContext } from '../../context/UserStateContext';
import { fetchUserState } from '../../contextManager/userState';
import UserStateService from '../services/UserStateService';

function useUserState(): [boolean, boolean, boolean, boolean, string | undefined] {
  const [isLoading, setIsLoading] = useState(true);
  const [userSessionIsProcessed, setUserSessionIsProcessed] = useState(false);
  const [userStateIsReady, setUserStateIsReady] = useState(false);
  const [isActiveUserSession, setIsActiveUserSession] = useState(false);
  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  const { setUserState } = useContext(UserStateContext);

  useEffect(() => {
    const loadState = async () => {
      setIsLoading(true);

      if (!userSessionIsProcessed) {
        const userSessionProcessingResult = await UserStateService.processUserSession({
          context: 'Api -> Hooks -> useUserState',
          preferDethrottleCache: false,
        });

        if (userSessionProcessingResult.error.code === '0') {
          setIsActiveUserSession(true);
          setAuthToken(userSessionProcessingResult.data.rawStr);
        }

        setUserSessionIsProcessed(true);
      }

      if (isActiveUserSession && !userStateIsReady) {
        await fetchUserState({ setUserState });
        setUserStateIsReady(true);
      }

      if (userSessionIsProcessed && userStateIsReady) {
        setIsLoading(false);
      }
    };

    loadState();
  }, [userSessionIsProcessed, userStateIsReady]);

  return [isLoading, userSessionIsProcessed, isActiveUserSession, userStateIsReady, authToken];
}

export default useUserState;
