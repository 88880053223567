import React, { createContext, FC, useMemo, useState } from 'react';
import { UserContextState, IUserState } from './UserStateTypes';

export const DEFAULT_USER_STATE: IUserState = {
  userId: '',
  userInfo: {},
  topicState: {},
  topicProgress: {},
  createdUts: 0,
  updatedUts: 0,
};

const DEFAULT_STATE: UserContextState = {
  userState: DEFAULT_USER_STATE,
  setUserState: () => {},
};

const UserStateContext = createContext<UserContextState>(DEFAULT_STATE);

const UserStateProvider: FC = ({ children }) => {
  const [userState, setUserState] = useState<IUserState>(DEFAULT_USER_STATE);

  const value: UserContextState = useMemo(
    () => ({ userState, setUserState }),
    [userState.updatedUts, userState.userInfo?.fname],
  );

  return (
    <UserStateContext.Provider value={value}>
      {children}
    </UserStateContext.Provider>
  );
};

export { UserStateProvider, UserStateContext };
